export default {
  arr1: [{
    label: '公司类型',
    type: 'select',
    options: 'typeOptions',
    key: 'company_type',
    validatRule: 'require',
    show: true,
    disabled: false,
    placeholder: '请选择商户类型',
  },
  {
    label: '公司网站',
    type: 'input',
    key: 'company_website',
    // validatRule: 'require',
    show: true,
    disabled: false,
    placeholder: '请输入公司网站',
  },
  {
    label: 'Icp备案号',
    type: 'input',
    key: 'icp_no',
    show: true,
    disabled: false,
    placeholder: '请输入Icp备案号',
  },
  {
    label: '商户名称',
    type: 'input',
    key: 'mch_name',
    validatRule: 'require|length:1:10',
    show: true,
    disabled: false,
    placeholder: '请输入商户名称',
  },
  {
    label: '商户简称',
    type: 'input',
    key: 'merchant_short_name',
    validatRule: 'require|length:1:10',
    show: true,
    disabled: false,
    placeholder: '请输入商户简称',
  },
  {
    label: '商户证件类型',
    type: 'select',
    key: 'business_cert_type',
    options: 'businessCertTypeOptions',
    validatRule: 'require',
    show: true,
    disabled: false,
    placeholder: '请输入商户证件类型',
  },
  ],
  arr2: [{
    label: '商户证件号码',
    type: 'input',
    key: 'business_license_no',
    validatRule: 'require',
    show: true,
    disabled: false,
    placeholder: '请输入证件号码',
  }, {
    label: '商户证件有效期',
    type: 'daterange',
    key: 'licenceDate',
    startKey: 'business_license_start',
    endKey: 'business_license_end',
    default: [],
    show: true,
    disabled: false,
  }, {
    label: '行业类别',
    type: 'select',
    options: 'categoryOptions',
    key: 'industry_category',
    validatRule: 'require',
    show: true,
    disabled: false,
    placeholder: '请选择行业类别',
  }, {
    label: '商户所在地',
    type: 'cascader',
    options: 'addressOptions',
    key: 'shopAddress',
    show: true,
    disabled: false,
    placeholder: '省/市/区',
  }],
  arr3: [{
    label: '法人姓名',
    type: 'input',
    key: 'legal_name',
    validatRule: 'require',
    show: true,
    disabled: false,
    placeholder: '请输入法人姓名',
  }, {
    label: '法人身份证号码',
    type: 'input',
    key: 'legal_entity_id',
    validatRule: 'require',
    show: true,
    disabled: false,
    placeholder: '请输入法人身份证号码',
  }, {
    label: '法人证件有效期',
    type: 'date',
    key: 'legal_entity_id_vaild',
    validatRule: 'require',
    show: true,
    disabled: false,
    placeholder: '结束日期',
  },
  // {
  //   label: '法人联系地址',
  //   type: 'input',
  //   key: 'contactAddress',
  //   validatRule: 'require',
  //   show: true,
  //   disabled: false,
  //   placeholder: '请输入法人联系地址',
  // },
  {
    label: '联系人姓名',
    type: 'input',
    key: 'contact_name',
    validatRule: 'require',
    show: true,
    disabled: false,
    placeholder: '请输入联系人姓名',
  }, {
    label: '联系人手机号',
    type: 'input',
    inputType: 'number',
    key: 'contact_phone',
    validatRule: 'require',
    show: true,
    disabled: false,
    placeholder: '请输入联系人手机号',
  }],
  arr4: [
    {
      label: '联系人身份证号码',
      type: 'input',
      key: 'contact_id_card_no',
      validatRule: 'require',
      show: true,
      disabled: false,
      placeholder: '请输入联系人身份证号码',
    },
    {
      label: '联系人证件有效期',
      type: 'date',
      key: 'contact_cert_valid',
      validatRule: 'require',
      show: true,
      disabled: false,
      placeholder: '结束日期',
    },
    {
      label: '联系邮箱',
      type: 'input',
      key: 'contact_email_one',
      validatRule: 'require',
      show: true,
      disabled: false,
      placeholder: '请输入联系邮箱',
    },
    // {
    //   label: '客服电话',
    //   type: 'input',
    //   key: 'servicePhone',
    //   validatRule: 'require',
    //   show: true,
    //   disabled: false,
    //   placeholder: '请输入客服电话',
    // },
    {
      label: '结算周期',
      type: 'select',
      options: 'settleTypeOptions',
      key: 'settle_type',
      validatRule: 'require',
      show: true,
      disabled: false,
      placeholder: '请选择结算周期',
    },
  ],
  arr5: [
    {
      label: '注册地址',
      type: 'input',
      key: 'register_address',
      validatRule: 'require',
      show: true,
      disabled: false,
      placeholder: '请输入注册地址/营业地址',
    },
    {
      label: '经营地址',
      type: 'input',
      key: 'operate_address',
      validatRule: 'require',
      show: true,
      disabled: false,
      placeholder: '请输入经营地址',
    },
  ],
  arr6: [
    {
      label: '注册资金',
      type: 'input',
      key: 'register_fund',
      inputType: 'number',
      validatRule: 'require',
      show: true,
      disabled: false,
      placeholder: '请输入注册资金(单位:元)',
    },
    {
      label: '经营范围',
      type: 'textarea',
      key: 'business_scope',
      validatRule: 'require',
      show: true,
      disabled: false,
      placeholder: '请输入经营范围',
    },
    {
      label: '账户类型',
      type: 'select',
      options: 'bankTypeOptions',
      key: 'bank_acct_type',
      validatRule: 'require',
      show: true,
      disabled: false,
      placeholder: '请输入账户类型',
    },
    {
      label: '结算账户名称',
      type: 'input',
      key: 'bank_account_name',
      validatRule: 'require',
      show: true,
      disabled: false,
      placeholder: '请输入结算账户名称',
    },
    {
      label: '结算账号',
      type: 'input',
      inputType: 'number',
      key: 'bank_account_no',
      validatRule: 'require',
      show: true,
      disabled: false,
      placeholder: '请输入结算账号',
    },
    {
      label: '银行名称',
      type: 'bank',
      options: 'bankOptions',
      key: 'bank_code',
      validatRule: 'require',
      show: true,
      disabled: false,
      placeholder: '请输入银行名称',
    },
    {
      label: '开户行地址',
      type: 'cascader',
      options: 'bankAddressOptions',
      key: 'bankAddress',
      show: true,
      disabled: false,
      placeholder: '省/市',
    },
    {
      label: '支付费率',
      type: 'input',
      inputType: 'number',
      key: 'pay_rate',
      validatRule: 'require',
      showUnit: '%',
      show: true,
      disabled: false,
      placeholder: '请输入支付费率',
    },
    {
      label: '控制人姓名',
      type: 'input',
      key: 'ctrl_name',
      show: true,
      disabled: false,
      placeholder: '请输入控制人姓名',
    },
    {
      label: '控制人身份证号',
      type: 'input',
      inputType: 'idcard',
      key: 'ctrl_entity_id',
      show: true,
      disabled: false,
      placeholder: '请输入控制人身份证号',
    },
    {
      label: '控制人证件过期时间',
      type: 'date',
      key: 'ctrl_entity_id_valid',
      show: true,
      disabled: false,
      placeholder: '结束日期',
    },
    {
      label: '控制人控股比例',
      type: 'input',
      inputType: 'number',
      key: 'ctrl_rate',
      showUnit: '%',
      show: true,
      disabled: false,
      placeholder: '请输入控制人控股比例',
    },
    {
      label: '受益人姓名',
      type: 'input',
      key: 'benef_name',
      show: true,
      disabled: false,
      placeholder: '请输入受益人姓名',
    },
    {
      label: '受益人身份证号',
      type: 'input',
      inputType: 'idcard',
      key: 'benef_entity_id',
      show: true,
      disabled: false,
      placeholder: '请输入受益人身份证号',
    },
    {
      label: '受益人证件过期时间',
      type: 'date',
      key: 'benef_entity_id_valid',
      show: true,
      disabled: false,
      placeholder: '结束日期',
    },
    {
      label: '受益人控股比例',
      type: 'input',
      inputType: 'number',
      key: 'benef_rate',
      showUnit: '%',
      show: true,
      disabled: false,
      placeholder: '请输入受益人控股比例',
    },
    {
      label: '受益人地址',
      type: 'input',
      key: 'benef_entity_address',
      show: true,
      disabled: false,
      placeholder: '请输入受益人地址',
    },
    // {
    //   label: '开户行名称',
    //   type: 'input',
    //   key: 'bankName',
    //   validatRule: 'require',
    //   show: true,
    //   disabled: false,
    //   placeholder: '请输入开户行名称',
    // },
    // {
    //   label: '通知地址',
    //   type: 'input',
    //   key: 'notifyUrl',
    //   validatRule: 'require',
    //   show: true,
    //   disabled: false,
    //   placeholder: '请输入通知地址',
    // },
  ],
  rule: [
    {
      condition: 'form.companyType > 0',
      option: {
        key: 'business_cert_type',
        show: true,
        disabled: true,
      },
    },
    {
      condition: 'form.company_type == 3',
      option: {
        key: 'ctrl_name',
        show: false,
        disabled: true,
      },
    },
    {
      condition: 'form.company_type == 3',
      option: {
        key: 'ctrl_entity_id',
        show: false,
        disabled: true,
      },
    },
    {
      condition: 'form.company_type == 3',
      option: {
        key: 'ctrl_entity_id_valid',
        show: false,
        disabled: true,
      },
    },
    {
      condition: 'form.company_type == 3',
      option: {
        key: 'ctrl_rate',
        show: false,
        disabled: true,
      },
    },
    {
      condition: 'form.company_type == 3',
      option: {
        key: 'benef_name',
        show: false,
        disabled: true,
      },
    },
    {
      condition: 'form.company_type == 3',
      option: {
        key: 'benef_entity_id',
        show: false,
        disabled: true,
      },
    },
    {
      condition: 'form.company_type == 3',
      option: {
        key: 'benef_entity_id_valid',
        show: false,
        disabled: true,
      },
    },
    {
      condition: 'form.company_type == 3',
      option: {
        key: 'benef_rate',
        show: false,
        disabled: true,
      },
    },
    {
      condition: 'form.company_type == 3',
      option: {
        key: 'benef_entity_address',
        show: false,
        disabled: true,
      },
    },
  ],
};
