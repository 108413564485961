
import api from './api';

// 进件列表
export const $sg_applyMerchantList = (params) => api.get('/p/pay/platform/income/list', params);

// 商户审核
export const $sp_applyMerchantExamine = (params) => api.post('/p/pay/platform/income/verify', params);

// 保存商户
export const $sp_merchantCreate = (params) => api.post('/p/pay/platform/income/save', params);

// 商户详情
export const $sg_merchantInfo = (params) => api.get('/p/pay/platform/income/detail', params);

// 获取树形结构区域信息
export const $sg_getListByTree = (params) => api.get('/p/pay/platform/getTree', params);

// 获取城市编码 树形结构
export const $sg_getCode = (params) => api.get('/p/pay/platform/getCode', params);
