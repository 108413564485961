<template>
  <div class="applyDetailFormItem" v-show="item.show">
    <div class="form-item flexbox flex-align-center">
      <span>{{item.label}}</span>
      <el-input v-model="form[item.key]" :placeholder="item.placeholder" style="width: 200px;" :class="item.showUnit ? 'show-unit-box' : ''"
        v-if="item.type == 'input'" :type="item.inputType || 'text'" :clearable="item.showUnit ? false : true" :disabled="item.disabled || disabled">
        <template slot="suffix">{{item.showUnit}}</template>
      </el-input>
      <el-input v-model="form[item.key]" :placeholder="item.placeholder" style="width: 200px;"
        type="textarea" :rows="2" v-if="item.type == 'textarea'" clearable :disabled="item.disabled || disabled"></el-input>
      <el-select v-model="form[item.key]" :placeholder="item.placeholder" style="width: 200px;"
        v-if="item.type == 'select'" clearable :disabled="item.disabled || disabled">
        <el-option
          v-for="item in options[item.options]"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-select v-model="form[item.key]" :placeholder="item.placeholder" style="width: 200px;"
        v-if="item.type == 'bank'" filterable clearable :disabled="item.disabled || disabled">
        <el-option
          v-for="item in options[item.options]"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-cascader v-model="form[item.key]"
        :options="options[item.options]"
        :placeholder="item.placeholder"
        style="width: 200px;"
        v-if="item.type == 'cascader'"
        clearable
        :disabled="item.disabled || disabled"
        :props="{
          value: 'area_id',
          label: 'area_name',
          children: 'child',
        }"
        ></el-cascader>
      <div v-if="item.type == 'daterange'" class="flexbox flex-align-center">
        <el-date-picker
          v-if="+form[item.key] !== 1"
          :disabled="item.disabled || disabled"
          style="width: 240px;"
          v-model="form[item.key]"
          type="daterange"
          value-format="yyyyMMdd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
        <span :class="+form[item.key] === 1 ? 'long-time-active' : 'long-time'" @click="daterangeLongTime(item.key, '1')">长期</span>
      </div>
      <div v-if="item.type == 'date'" class="flexbox flex-align-center">
        <el-date-picker
          v-if="+form[item.key] !== 1"
          :disabled="item.disabled || disabled"
          style="width: 200px;"
          v-model="form[item.key]"
          type="date"
          value-format="yyyyMMdd"
          :placeholder="item.placeholder"
          @focus="form[item.key] = ''">
        </el-date-picker>
        <span :class="+form[item.key] === 1 ? 'long-time-active' : 'long-time'" @click="daterangeLongTime(item.key, '2')">长期</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {

    };
  },
  methods: {
    // 时间选择长期
    daterangeLongTime(key, type) {
      if (this.disabled) return;
      if (+this.form[key] === 1) {
        if (type === '1') {
          this.form[key] = [];
        } else {
          this.form[key] = '';
        }
      } else {
        this.form[key] = 1;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.form-item {
  width: 100%;
  flex-shrink: 0;
  margin-top: 10px;
  & > span {
    width: 120px;
    text-align: right;
    margin-right: 10px;
  }
  .long-time-active {
    display: inline-block;
    width: 45px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    border-radius: 5px;
    background-color: #F22424;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    user-select: none;
  }
  .long-time {
    display: inline-block;
    width: 45px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    user-select: none;
    color: #F22424;
    border: 1px solid #F22424;
    box-sizing: border-box;
  }
}
</style>
<style lang="scss">
.applyDetailFormItem {
  .form-item {
    .show-unit-box {
      .el-input__suffix {
        top: 10px;
      }
    }
  }
}
</style>
