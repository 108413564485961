
<template>
  <div class="in-coming-pay-index-page">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'index',
  components: {
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.in-coming-pay-index-page{
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
</style>
