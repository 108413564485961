<template>
  <div class="setting distribution" v-loading="loading" element-loading-text="数据获取中..." element-loading-customClass='k-ele-loading'>
    <notCreated @setting="edit('add')" v-if="isCreated" />
    <!-- 查看 -->
    <div class="look" v-if="isLook">
      <el-button v-if="isLook" type='primary' class="fixed-btn" @click="edit('edit')">修改设置</el-button>
      <p class="level1Titel">跑腿配送设置</p>
      <p v-if="!fromSettinInfo.runErrandsConfig.feeConfigList.length" class="noset">暂未配置</p>
      <template v-else>
        <infoListBorder class="showDetailInfo" title="配送距离计费设置(帮买/帮取/帮送)" :required="1">
          <div v-if="getPTArrayData('StartDistance').length">
            <p v-for="(itemc,indexc) in getPTArrayData('StartDistance')" :key="indexc">
              起<span>{{itemc.scopek[0]}}km</span>至<span>{{itemc.scopek[1]}}km</span>
              <span style="font-weight:400;width:60px;text-align:left;margin:0;">{{!indexc?'起步价':'加价金额'}}</span>
              <span>{{itemc.price}}元</span>
            </p>
            <p style="color:#f63d2a;margin-top:10px;">超出距离每km自动加 "最后一个梯度加价金额+0.5" 元</p>
          </div>
          <p v-else>暂未配置</p>
        </infoListBorder>
        <infoListBorder class="showDetailInfo" title="重量设置(帮买/帮取/帮送)" :required="1">
          <div v-if="getPTArrayData('Weight').length">
            <p v-for="(itemc,indexc) in getPTArrayData('Weight')" :key="indexc">
              起<span>{{itemc.scopek[0]}}kg</span>至<span>{{itemc.scopek[1]}}kg</span>加价金额<span>{{itemc.price}}元</span>
            </p>
          </div>
          <p v-else>暂未配置</p>
        </infoListBorder>
        <infoListBorder class="tabelDetailInfo" title="特殊时间段加价设置(帮买/帮取/帮送/帮排队/全能帮手)">
          <div v-if="getPTArrayData('Peak').length">
            <p><span>开始时间</span><span>结束时间</span><span>加价金额</span></p>
            <p v-for="(itemc,indexc) in getPTArrayData('Peak')" :key="indexc">
              <span>{{formattingTime(itemc.scopek[0][0]+itemc.scopek[0][1])}}</span>
              <span>{{formattingTime(itemc.scopek[1][0]+itemc.scopek[1][1])}}</span>
              <span>{{itemc.price}}元</span>
            </p>
          </div>
          <p v-else>暂未配置</p>
        </infoListBorder>
        <infoListBorder class="showDetailInfo" title="帮排队基础计费设置" :required="1">
          <p>
            起步价(≤60min)<span>{{getPTArrayData('QueueUpBasics')[0].price}}元</span>
            超出后时间<span>{{getPTArrayData('QueueUpBasics')[0].extraPrice}}元/30min</span>
          </p>
        </infoListBorder>
        <infoListBorder class="showDetailInfo" title="全能帮手基础计费设置" :required="1">
          <p v-for="(itemc,indexc) in getPTArrayData('VersatileBasics')" :key="indexc"><span
              class="center-width">{{helper[indexc]}}</span><span class="price">{{itemc.price}}元/h</span></p>
        </infoListBorder>
      </template>
    </div>
    <!-- 修改 -->
    <div v-if="isEdit">
      <div class="set-header">
        <steps :next="next" />
      </div>
      <div class='settingBox'>
        <errandDistribution ref="errandDistribution" v-show='next === 1' />
      </div>
    </div>
  </div>
</template>

<script>
import { getSeconds, parseSeconds, deepClone } from '@base/utils';
import { $sg_psConfigGet } from '@/api/setting';
import notCreated from './components/notCreated.vue';
import steps from './components/steps.vue';
import infoListBorder from './components/infoListBorder.vue';
import errandDistribution from './components/errandDistribution.vue';

export default {
  components: {
    notCreated,
    steps,
    errandDistribution,
    infoListBorder,
  },
  data() {
    return {
      loading: false,
      isCreated: false, // 暂无设置信息
      isEdit: false, // 编辑
      isLook: false, // 查看
      settingType: null, // 设置类型 add 新增 edit 编辑
      next: null, // 设置进度
      fromSettinInfo: { // 所有的信息
        feeDimsTypeConfig: null,
        runErrandsConfig: { // 跑腿配送设置
          feeConfigList: [
            {
              calculateType: 'StartDistance', // 配送距离计费设置
              configCondList: [],
            },
            {
              calculateType: 'Weight', // 重量设置(帮买/帮取/帮送)
              configCondList: [],
            },
            {
              calculateType: 'Peak', // 特殊时间段加价设置(帮买/帮取/帮送/帮排队/全能帮手)
              configCondList: [],
            },
            {
              calculateType: 'QueueUpBasics', // 帮排队基础计费设置
              configCondList: [
                {
                  scope: 60, // 范围 基本服务时间 分钟
                  price: null, // 单价
                  minutes: 30, // 超出时间分钟数
                  extraPrice: null, // 超出单价
                  serial: 1,
                },
              ],
            },
            {
              calculateType: 'VersatileBasics', // 全能帮手基础计费设置
              configCondList: [
                {
                  ruleType: 'Versatile',
                  price: null,
                  serial: 1,
                },
                {
                  ruleType: 'Carrying',
                  price: null,
                  serial: 2,
                },
                {
                  ruleType: 'Hourly',
                  price: null,
                  serial: 3,
                },
                {
                  ruleType: 'VisitCook',
                  price: null,
                  serial: 4,
                },
                {
                  ruleType: 'Pet',
                  price: null,
                  serial: 5,
                },
                {
                  ruleType: 'DistributeFlyer',
                  price: null,
                  serial: 6,
                },
                {
                  ruleType: 'Activities',
                  price: null,
                  serial: 7,
                },
                {
                  ruleType: 'RemoteCharge',
                  price: null,
                  serial: 8,
                },
              ],
            },
          ],
        },
        earningsConfig: null,
      },
      tiems: ['', '一', '二', '三'],
      helper: ['全能帮手服务费', '搬运物品服务费', '小时工服务费', '上门做饭服务费', '照顾宠物服务费', '派发传单服务费', '活动招募服务费', '异地代办服务费'],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 秒数转时分
    formattingTime(seconds) {
      return parseSeconds(seconds);
    },
    // 获取跑腿设置数据
    getPTArrayData(type) {
      const dataArray = this.fromSettinInfo.runErrandsConfig.feeConfigList;
      const itemD = dataArray.find((item) => item.calculateType === type);
      return itemD && itemD.configCondList.length ? itemD.configCondList : [];
    },
    getList(getInfoType = null) {
      if (getInfoType) {
        this.fromSettinInfo = this.$options.data().fromSettinInfo;
      }
      this.loading = true;
      $sg_psConfigGet().then((res) => {
        const {
          runErrandsConfig: { feeConfigList },
        } = res;
        // 跑腿配送设置
        if (feeConfigList.length) {
          this.setShowStatus(false, false, true);
          for (let i = 0; i < feeConfigList.length; i += 1) {
            const itemArray = feeConfigList[i].configCondList;
            const type = feeConfigList[i].calculateType;
            if (itemArray && itemArray.length) {
              for (let j = 0; j < itemArray.length; j += 1) {
                const scopeArray = itemArray[j].scope.split('~');
                if (type === 'StartDistance' || type === 'Weight') {
                  itemArray[j].scopek = scopeArray;
                }
                if (type === 'Peak') {
                  const startMinute = getSeconds(scopeArray[0]);
                  const startSeconds = startMinute % 3600;
                  const endMinute = getSeconds(scopeArray[1]);
                  const endSeconds = endMinute % 3600;
                  itemArray[j].scopek = [[startMinute - startSeconds, startSeconds], [endMinute - endSeconds, endSeconds]];
                }
              }
            }
          }
        } else {
          this.setShowStatus(true, false, false);
        }
        if (feeConfigList.length) {
          const bfArray = deepClone(this.fromSettinInfo.runErrandsConfig.feeConfigList);
          const peakIndex = feeConfigList.findIndex((item) => item.calculateType === 'Peak');
          if (peakIndex === -1) {
            this.fromSettinInfo.runErrandsConfig.feeConfigList[2].configCondList = [];
          }
          feeConfigList.forEach((item) => {
            const fIndex = bfArray.findIndex((itemc) => itemc.calculateType === item.calculateType);
            if (fIndex > -1) {
              this.fromSettinInfo.runErrandsConfig.feeConfigList.splice(fIndex, 1, item);
            }
          });
        }
        console.log(this.fromSettinInfo.runErrandsConfig, 'feeConfigList');
        // this.fromSettinInfo = res;
      }).finally(() => {
        this.loading = false;
      });
    },
    // 点击修改
    edit(type) {
      this.settingType = type;
      this.next = 1;
      this.setShowStatus(false, true, false);
    },
    setShowStatus(cre = false, edit = false, look = false) {
      this.isCreated = cre;
      this.isEdit = edit;
      this.isLook = look;
    },
    saveSuccess() {
      this.next = null;
      this.getList();
    },
    // 设置时间选择项
    datePickerData() {
      const arr = [];
      for (let i = 0; i < 24; i += 1) {
        const json = {
          label: i < 10 ? `0${i}` : i,
          value: i * 3600,
          children: [],
        };
        for (let k = 0; k < 60; k += 1) {
          json.children.push({
            label: k < 10 ? `0${k}` : k,
            value: k * 60,
          });
        }
        arr.push(json);
      }
      return arr;
    },
  },
};
</script>

<style lang="scss" scoped>
.level1Titel {
  font-size: 16px;
  font-weight: bold;
  margin: 20px 0;
}
.isRequired {
  color: #f63d2a;
}
.setting {
  height: 100%;
  .set-header {
    height: 116px;
    background: #fff;
    margin-bottom: 20px;
  }
  .settingBox {
    width: 100%;
    background: #fff;
    padding: 10px;
    box-sizing: border-box;
  }
  .look {
    position: relative;
    background: #fff;
    padding: 10px;
  }
  .fixed-btn {
    position: absolute;
    top: 42px;
    right: 30px;
  }
}
.showDetailNowidth p,
.showDetailInfo p,
.tabelDetailInfo p {
  margin-top: 10px;
  color: #666;
  span {
    display: inline-block;
  }
}
.showDetailNowidth span {
  margin-right: 30px;
  font-weight: bold;
  color: #444;
}
.showDetailInfo {
  span:not(.langLabel):not(.center-width) {
    width: 100px;
    margin: 0 10px;
    text-align: center;
    font-weight: bold;
    color: #444;
  }
  .center-width {
    width: 160px;
  }
  .langLabel {
    width: 400px;
  }
}
.tabelDetailInfo span {
  width: 180px;
  font-weight: bold;
  color: #444;
}
.noset {
  color: #666;
  box-sizing: border-box;
  padding-left: 30px;
}
</style>
