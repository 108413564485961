<template>
  <div class="distribution">
    <el-button type="danger" round size='medium' icon="el-icon-refresh-left" class="resetSetting" @click="resetSetting">恢复默认</el-button>
    <!-- 跑腿配送设置 -->
    <!-- <infoListBorder title="配送距离计费设置(帮买/帮取/帮送)" :required="1">
      <div class="distribution-fee-item" v-for="(item, index) in feeConfigList[0].configCondList" :key="index">
        <div>
          <span>起<span class="psDistanceStart">{{item.scopek[0] || 0}} km</span>至</span>
          <el-input size='small' class="inputInfo" type="number" clearable v-model="item.scopek[1]"
            @input="(val)=>{distanceChange(val,0,index)}">
          </el-input> km
        </div>
        <div>{{!index?'起步价':'加价金额'}}<el-input size='small' class="inputInfo" clearable v-model="item.price"></el-input> 元 </div>
        <el-button type='text' icon="el-icon-delete" @click="deleteDataList(0,index)">删除</el-button>
      </div>
      <el-button class="addTimer" icon="el-icon-circle-plus-outline" type='primary' plain @click="addDataList(0)">新增配送距离区间</el-button>
      <p style="color:#f63d2a;margin-top:10px;">超出距离每km自动加 "最后一个梯度加价金额+0.5" 元</p>
    </infoListBorder>

    <infoListBorder title="重量设置(帮买/帮取/帮送)" :required="1">
      <div class="distribution-fee-item" v-for="(item, index) in feeConfigList[1].configCondList" :key="index">
        <div>
          <span>起<span class="psDistanceStart">{{item.scopek[0] || 0}} kg</span>至</span>
          <el-input size='small' class="inputInfo" type="number" clearable v-model="item.scopek[1]"
            @input="(val)=>{distanceChange(val,1,index)}">
          </el-input> kg
        </div>
        <div>加价金额<el-input size='small' class="inputInfo" clearable v-model="item.price"></el-input> 元 </div>
        <el-button type='text' icon="el-icon-delete" @click="deleteDataList(1,index)">删除</el-button>
      </div>
      <el-button class="addTimer" icon="el-icon-circle-plus-outline" type='primary' plain @click="addDataList(1)">新增重量区间</el-button>
    </infoListBorder>

    <infoListBorder title="特殊时间段加价设置(帮买/帮取/帮送/帮排队/全能帮手)">
      <div class="peak-item-top" v-if="feeConfigList[2].configCondList.length">
        <div>开始时间</div>
        <div>结束时间</div>
        <div>加价金额</div>
      </div>
      <div v-for="(item, index) in feeConfigList[2].configCondList" :key="index" class="peak-item">
        <div class="peak-item-bottom">
          <div class="peak-bottom-box">
            <el-cascader v-model="item.scopek[0]" size='small' :options="optionsStartTime" clearable placeholder="选择开始时间" separator=":">
            </el-cascader>
          </div>
          <span class="as">至</span>
          <div class="peak-bottom-box">
            <el-cascader v-model="item.scopek[1]" size='small' :options="optionsEndTime" clearable placeholder="选择结束时间" separator=":">
            </el-cascader>
          </div>
          <div class="peak-bottom-box last-peak-item">
            <el-input size='small' clearable type="number" v-model="item.price"></el-input> <span class="ml5">元</span>
          </div>
          <el-button type='text' icon="el-icon-delete" @click="deleteDataList(2,index)">删除</el-button>
        </div>
      </div>
      <el-button class="addTimer" icon="el-icon-circle-plus-outline" type='primary' plain @click="addDataList(2)">新增时间段</el-button>
    </infoListBorder>

    <infoListBorder title="帮排队基础计费设置" :required="1">
      <div class="distribution-fee ptSetInfo">
        <span>起步价(≤60min)</span>
        <el-input size='small' class="inputInfo" clearable type="number" v-model="feeConfigList[3].configCondList[0].price"></el-input>元，
        超出后时间
        <el-input size='small' class="inputInfo" clearable type="number" v-model="feeConfigList[3].configCondList[0].extraPrice"></el-input>
        元/30min
      </div>
    </infoListBorder>

    <infoListBorder title="全能帮手基础计费设置" :required="1">
      <div class="distribution-fee ptSetInfo helperSet" v-for="(item,index) in feeConfigList[4].configCondList" :key="index">
        <span class="helperSet-label">{{helper[item.serial]}}</span>
        <el-input size='small' class="inputInfo" clearable type='number' v-model="item.price"></el-input>元/h
      </div>
    </infoListBorder> -->
    <infoListBorder title="配送距离计费设置(帮买/帮取/帮送)" :required="1">
      <!-- feeConfigList[0].configCondList -->
      <div class="distribution-fee-item" v-for="(item, index) in getArray('StartDistance')" :key="index">
        <div>
          <span>起<span class="psDistanceStart">{{item.scopek[0] || 0}} km</span>至</span>
          <el-input size='small' class="inputInfo" type="number" clearable v-model="item.scopek[1]"
            @input="(val)=>{distanceChange(val,0,index)}">
          </el-input> km
        </div>
        <div>{{!index?'起步价':'加价金额'}}<el-input size='small' class="inputInfo" clearable v-model="item.price"></el-input> 元 </div>
        <el-button type='text' icon="el-icon-delete" @click="deleteDataList(0,index)">删除</el-button>
      </div>
      <el-button class="addTimer" icon="el-icon-circle-plus-outline" type='primary' plain @click="addDataList(0)">新增配送距离区间</el-button>
      <p style="color:#f63d2a;margin-top:10px;">超出距离每km自动加 "最后一个梯度加价金额+0.5" 元</p>
    </infoListBorder>

    <infoListBorder title="重量设置(帮买/帮取/帮送)" :required="1">
      <div class="distribution-fee-item" v-for="(item, index) in getArray('Weight')" :key="index">
        <div>
          <span>起<span class="psDistanceStart">{{item.scopek[0] || 0}} kg</span>至</span>
          <el-input size='small' class="inputInfo" type="number" clearable v-model="item.scopek[1]"
            @input="(val)=>{distanceChange(val,1,index)}">
          </el-input> kg
        </div>
        <div>加价金额<el-input size='small' class="inputInfo" clearable v-model="item.price"></el-input> 元 </div>
        <el-button type='text' icon="el-icon-delete" @click="deleteDataList(1,index)">删除</el-button>
      </div>
      <el-button class="addTimer" icon="el-icon-circle-plus-outline" type='primary' plain @click="addDataList(1)">新增重量区间</el-button>
    </infoListBorder>

    <infoListBorder title="特殊时间段加价设置(帮买/帮取/帮送/帮排队/全能帮手)">
      <div v-if="getArray('Peak').length">
        <div class="peak-item-top">
          <div>开始时间</div>
          <div>结束时间</div>
          <div>加价金额</div>
        </div>
        <div v-for="(item, index) in getArray('Peak')" :key="index" class="peak-item">
          <div class="peak-item-bottom">
            <div class="peak-bottom-box">
              <el-cascader v-model="item.scopek[0]" size='small' :options="optionsStartTime" clearable placeholder="选择开始时间" separator=":">
              </el-cascader>
            </div>
            <span class="as">至</span>
            <div class="peak-bottom-box">
              <el-cascader v-model="item.scopek[1]" size='small' :options="optionsEndTime" clearable placeholder="选择结束时间" separator=":">
              </el-cascader>
            </div>
            <div class="peak-bottom-box last-peak-item">
              <el-input size='small' clearable type="number" v-model="item.price"></el-input> <span class="ml5">元</span>
            </div>
            <el-button type='text' icon="el-icon-delete" @click="deleteDataList(2,index)">删除</el-button>
          </div>
        </div>
      </div>
      <el-button class="addTimer" icon="el-icon-circle-plus-outline" type='primary' plain @click="addDataList(2)">新增时间段</el-button>
    </infoListBorder>

    <infoListBorder title="帮排队基础计费设置" :required="1">
      <div class="distribution-fee ptSetInfo">
        <span>起步价(≤60min)</span>
        <el-input size='small' class="inputInfo" clearable type="number" v-model="getArray('QueueUpBasics')[0].price"></el-input>元，
        超出后时间
        <el-input size='small' class="inputInfo" clearable type="number" v-model="getArray('QueueUpBasics')[0].extraPrice"></el-input>
        元/30min
      </div>
    </infoListBorder>

    <infoListBorder title="全能帮手基础计费设置" :required="1">
      <div class="distribution-fee ptSetInfo helperSet" v-for="(item,index) in getArray('VersatileBasics')" :key="index">
        <span class="helperSet-label">{{helper[item.serial]}}</span>
        <el-input size='small' class="inputInfo" clearable type='number' v-model="item.price"></el-input>元/h
      </div>
    </infoListBorder>
    <div class="bottom-btn-group">
      <PrevReclick>
        <el-button size='small' type='primary' @click="submitInfo" :loading="isSubmit" :disabled="isSubmit">提交设置</el-button>
      </PrevReclick>
    </div>
  </div>
</template>

<script>
import { $sp_psConfigAdd, $sp_psConfigUpdate } from '@/api/setting';
import { parseSeconds, deepClone } from '@base/utils';
import infoListBorder from './infoListBorder.vue';

export default {
  data() {
    return {
      optionsStartTime: [], // 开始时间
      optionsEndTime: [], // 结束时间
      isSubmit: false,
      helper: ['', '全能帮手服务费', '搬运物品服务费', '小时工服务费', '上门做饭服务费', '照顾宠物服务费', '派发传单服务费', '活动招募服务费', '异地代办服务费'],
      feeConfigList: [
        {
          calculateType: 'StartDistance', // 配送距离计费设置
          configCondList: [
            {
              scope: '0~3',
              scopek: [0, 3],
              price: 6,
              serial: 1,
            },
            {
              scope: '3~5',
              scopek: [3, 5],
              price: 2,
              serial: 2,
            },
            {
              scope: '5~10',
              scopek: [5, 10],
              price: 3,
              serial: 3,
            },
          ], // scope:'0~5' 距离  price 金额 serial 序号
        },
        {
          calculateType: 'Weight', // 重量设置(帮买/帮取/帮送)
          configCondList: [
            {
              scope: '0~5',
              scopek: [0, 5],
              price: 5,
              serial: 1,
            },
            {
              scope: '5~10',
              scopek: [5, 10],
              price: 10,
              serial: 2,
            },
          ],
        },
        {
          calculateType: 'Peak', // 特殊时间段加价设置(帮买/帮取/帮送/帮排队/全能帮手)
          configCondList: [
            {
              scopek: [[0, 0], [7200, 0]],
              scope: '00:00~02:00',
              price: 1,
              serial: 1,
            },
            {
              scopek: [[79200, 0], [82800, 3540]],
              scope: '22:00~23:59',
              price: 0.5,
              serial: 2,
            },
          ],
        },
        {
          calculateType: 'QueueUpBasics', // 帮排队基础计费设置
          configCondList: [
            {
              scope: 60, // 范围 基本服务时间 分钟
              price: 30, // 单价
              minutes: 30, // 超出时间分钟数
              extraPrice: 10, // 超出单价
              serial: 1,
            },
          ],
        },
        {
          calculateType: 'VersatileBasics', // 全能帮手基础计费设置
          configCondList: [
            {
              ruleType: 'Versatile',
              price: 30,
              serial: 1,
            },
            {
              ruleType: 'Carrying',
              price: 30,
              serial: 2,
            },
            {
              ruleType: 'Hourly',
              price: 30,
              serial: 3,
            },
            {
              ruleType: 'VisitCook',
              price: 30,
              serial: 4,
            },
            {
              ruleType: 'Pet',
              price: 30,
              serial: 5,
            },
            {
              ruleType: 'DistributeFlyer',
              price: 30,
              serial: 6,
            },
            {
              ruleType: 'Activities',
              price: 30,
              serial: 7,
            },
            {
              ruleType: 'RemoteCharge',
              price: 30,
              serial: 8,
            },
          ],
        },
      ],
    };
  },
  components: {
    infoListBorder,
  },
  created() {
    this.beifen = deepClone(this.feeConfigList);
    const timeLists = this.$parent.datePickerData();
    this.optionsStartTime = timeLists;
    this.optionsEndTime = timeLists;
    const addType = this.$parent.settingType === 'add' ? 0 : 1;
    if (addType) {
      const { feeConfigList } = this.$parent.fromSettinInfo.runErrandsConfig;
      this.feeConfigList = deepClone(feeConfigList);
    }
  },
  methods: {
    getArray(type) {
      const item = this.feeConfigList.find((itemc) => itemc.calculateType === type);
      return item ? item.configCondList : [];
    },
    // 重置设置
    resetSetting() {
      this.feeConfigList = deepClone(this.beifen);
    },
    // 添加表单数据
    addDataList(type) {
      const len = this.feeConfigList[type].configCondList.length;
      const dataArray = this.feeConfigList[type].configCondList;
      let scopek = [];
      switch (type) {
        case 0:
        case 1:
          if (this.validationDistanceOrWeight(type)) {
            return;
          }
          scopek = [dataArray[len - 1] ? dataArray[len - 1].scopek[1] : 0, null];
          this.feeConfigList[type].configCondList.push({
            scopek,
            scope: null,
            price: null,
          });
          break;
        case 2:
          if (this.validationTimePeriodOfTime()) {
            return;
          }
          this.feeConfigList[type].configCondList.push({
            scopek: [],
            scope: null,
            price: null,
          });
          break;
        default:
          break;
      }
    },
    // 距离配置输入框变化
    distanceChange(val, type, index) {
      const arrayData = this.feeConfigList[type].configCondList;
      if (arrayData[index + 1]) {
        arrayData[index + 1].scopek[0] = val;
      }
    },
    // 删除表单数据
    deleteDataList(type, index) {
      this.$confirm('此操作将删除该条设置, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.feeConfigList[type].configCondList.splice(index, 1);
        this.$message({
          type: 'success',
          message: '删除成功!',
        });
      });
    },
    // 校验特殊时间段
    validationTimePeriodOfTime() {
      const dataArray = this.feeConfigList[2].configCondList;
      const REG_PRICE = /^(\d{1,4}(\.[0-9]{1})?)$|^9999$/;
      let errorInfo = '';
      if (dataArray.length) {
        for (let i = 0; i < dataArray.length; i += 1) {
          const item = dataArray[i];
          if (!item.scopek[1] || !item.scopek[0]) {
            errorInfo = '请选择特殊时间段起始或结束时间';
            break;
          }
          if ((item.scopek[1][0] + item.scopek[1][1]) <= (item.scopek[0][0] + item.scopek[0][1])) {
            errorInfo = '特殊时间段结束时间需大于起始时间';
            break;
          }
          if (!REG_PRICE.test(item.price)) {
            errorInfo = '请正确输入特殊时间段加价金额，0~9999(可保留一位小数)';
            break;
          }
          for (let j = i + 1; j < dataArray.length; j += 1) {
            const itemc = dataArray[j];
            if (!itemc.scopek[1] || !itemc.scopek[0]) {
              errorInfo = '请选择特殊时间段起始或结束时间';
              break;
            }
            if ((itemc.scopek[0][0] + itemc.scopek[0][1] <= item.scopek[1][0] + item.scopek[1][1])) {
              errorInfo = '特殊时间段的开始时间需大于上一时间段的结束时间';
              break;
            }
            if ((itemc.scopek).join(',') === (item.scopek).join(',')) {
              errorInfo = '特殊时间段不能出现相同时间段';
              break;
            }
          }
        }
      }
      if (errorInfo) {
        this.$message.error(errorInfo);
      }
      return errorInfo;
    },
    // 验证配送距离计费 和 重量计费设置
    validationDistanceOrWeight(type, submit = false) {
      const dataArray = this.feeConfigList[type].configCondList;
      const REG_PRICE = /^(\d{1,4}(\.[0-9]{1})?)$|^9999$/;
      let errorInfo = '';
      if (submit && !dataArray.length) {
        errorInfo = `请完善配送${!type ? '距离' : '重量'}计费(帮买/帮取/帮送)设置`;
      }
      if (dataArray.length) {
        for (let i = 0; i < dataArray.length; i += 1) {
          const item = dataArray[i];
          if (Number(item.scopek[1]) <= Number(item.scopek[0])) {
            errorInfo = !type ? '配送距离结束公里数必须大于起始公里数' : '配送重量计费设置结束重量必须大于起始重量';
            break;
          }
          if (!REG_PRICE.test(item.scopek[1])) {
            errorInfo = `请正确输入${!type ? '配送距离公里数' : '配送重量'}，0~9999(可保留一位小数)`;
            break;
          }
          if (!REG_PRICE.test(item.price)) {
            errorInfo = `请正确输入${!type ? '配送距离计费' : '配送重量计费'}，0~9999(可保留一位小数)`;
            break;
          }
        }
      }
      if (errorInfo) {
        this.$message.error(errorInfo);
      }
      return errorInfo;
    },
    // 提交信息 进入下一环节
    submitInfo() {
      const methodLists = [$sp_psConfigAdd, $sp_psConfigUpdate];
      const methodsType = this.$parent.settingType === 'add' ? 0 : 1;
      if (this.validationDistanceOrWeight(0, true) || this.validationDistanceOrWeight(1, true)) {
        return;
      }
      if (this.feeConfigList[2].configCondList.length && this.validationTimePeriodOfTime()) {
        return;
      }
      const { price, extraPrice } = this.feeConfigList[3].configCondList[0];
      let errorInfo = '';
      const REG_PRICE = /^(\d{1,4}(\.[0-9]{1})?)$|^9999$/;
      if (!REG_PRICE.test(price) || !REG_PRICE.test(extraPrice)) {
        this.$message.error('请正确输入排队基础计费，0~9999(可保留一位小数)');
        return;
      }
      for (let i = 0; i < this.feeConfigList[4].configCondList.length; i += 1) {
        const item = this.feeConfigList[4].configCondList[i];
        // if (!item.price && item.price !== 0) {
        //   errorInfo = '请完善全能帮手基础计费设置';
        //   break;
        // }
        if (!REG_PRICE.test(item.price)) {
          errorInfo = '请正确输入全能帮手基础计费，0~9999(可保留一位小数)';
          break;
        }
      }
      if (errorInfo) {
        this.$message.error(errorInfo);
        return;
      }
      this.isSubmit = true;
      if (this.feeConfigList[0].configCondList.length) {
        for (let i = 0; i < this.feeConfigList[0].configCondList.length; i += 1) {
          const item = this.feeConfigList[0].configCondList[i];
          item.serial = i + 1;
          item.scope = item.scopek.join('~');
        }
      }
      if (this.feeConfigList[1].configCondList.length) {
        for (let i = 0; i < this.feeConfigList[1].configCondList.length; i += 1) {
          const item = this.feeConfigList[1].configCondList[i];
          item.serial = i + 1;
          item.scope = item.scopek.join('~');
        }
      }
      if (this.feeConfigList[2].configCondList.length) {
        for (let i = 0; i < this.feeConfigList[2].configCondList.length; i += 1) {
          const item = this.feeConfigList[2].configCondList[i];
          item.serial = i + 1;
          item.scope = `${parseSeconds(item.scopek[0][0] + item.scopek[0][1])}~${parseSeconds(item.scopek[1][0] + item.scopek[1][1])}`;
        }
      }
      const feeConfig = deepClone(this.feeConfigList);
      if (!this.feeConfigList[2].configCondList.length) {
        feeConfig.splice(2, 1);
      }
      this.$parent.fromSettinInfo.runErrandsConfig.feeConfigList = feeConfig;
      methodLists[methodsType]({ ...this.$parent.fromSettinInfo }).then(() => {
        this.$parent.saveSuccess();
        this.$message({
          message: '设置成功',
          type: 'success',
        });
      }).catch((err) => {
        if (err.msg) {
          this.$message.error(err.msg);
        }
      }).finally(() => {
        this.isSubmit = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.addTimer {
  margin-top: 20px;
}
.resetSetting {
  position: absolute;
  top: -10px;
  right: 30px;
}
.distribution {
  position: relative;
  .distribution-fee-item {
    display: flex;
    align-items: center;
    > div {
      text-align: left;
      margin-right: 30px;
    }
    .psDistanceStart {
      display: inline-block;
      min-width: 70px;
      text-align: center;
      margin: 0 6px;
    }
  }
  .peak-item-bottom {
    display: flex;
    align-items: center;
    .peak-bottom-box {
      width: 150px;
      margin-right: 10px;
    }
    .as {
      display: inline-block;
      margin-right: 10px;
    }
  }
  .last-peak-item {
    width: 200px;
    display: flex;
    align-items: center;
  }
  .bottom-btn-group {
    max-width: 1200px;
    margin-top: 100px;
  }
}
// 跑腿设置
.ptSetInfo {
  margin-top: 20px;
}
.inputInfo {
  width: 130px;
  margin: 0 10px;
}
.helperSet-label {
  display: inline-block;
  width: 120px;
}
.peak-item-top {
  display: flex;
  margin: 20px 0;
  div {
    flex: 0 0 160px;
    margin-right: 25px;
    &:nth-child(2) {
      margin: 0;
    }
  }
}
</style>
