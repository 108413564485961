<template>
  <div class="applyDetail">
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="1300px">
      <div class="form-box flexbox">
        <div class="form-item-box">
          <ApplyDetailFormItem v-for="(item) in formList.arr1" :key="item.label" :item="item" :form="form"
            :options="options" :disabled="disabled" />
          <div class="form-item flexbox">
            <span>商户证件</span>
            <div class="upload-box pointer" @click="img_upload('business_license_pic_name_url')">
              <img v-if="form.business_license_pic_name_url" :src="form.business_license_pic_name_url" class="upload-img">
              <div class="del-img-box flexbox flex-justify-around flex-align-center" v-if="form.business_license_pic_name_url">
                <i class="el-icon-zoom-in" @click.stop="previewImgEvent('business_license_pic_name_url')"></i>
                <i class="el-icon-edit"></i>
              </div>
              <div class="upload flexbox flex-tb flex-justify-center flex-align-center" v-if="!form.business_license_pic_name_url">
                <i class="el-icon-plus avatar-uploader-icon"></i>
                <div class="upload-tips">商户证件</div>
              </div>
            </div>
          </div>
          <ApplyDetailFormItem v-for="(item) in formList.arr2" :key="item.label" :item="item" :form="form"
            :options="options" :disabled="disabled" />
          <div class="form-item flexbox">
            <span>法人身份证</span>
            <div class="upload-box pointer" @click="img_upload('idCardFront')">
              <img v-if="form.idCardFront" :src="form.idCardFront" class="upload-img">
              <div class="del-img-box flexbox flex-align-center" v-if="form.idCardFront">
                <i class="el-icon-zoom-in" @click.stop="previewImgEvent('idCardFront')"></i>
                <i class="el-icon-edit"></i>
              </div>
              <div class="upload flexbox flex-tb flex-justify-center flex-align-center" v-if="!form.idCardFront">
                <i class="el-icon-plus avatar-uploader-icon"></i>
                <div class="upload-tips">法人身份证正面</div>
              </div>
            </div>
            <div class="upload-box pointer" @click="img_upload('idCardBack')">
              <img v-if="form.idCardBack" :src="form.idCardBack" class="upload-img">
              <div class="del-img-box flexbox flex-justify-around flex-align-center" v-if="form.idCardBack">
                <i class="el-icon-zoom-in" @click.stop="previewImgEvent('idCardBack')"></i>
                <i class="el-icon-edit"></i>
              </div>
              <div class="upload flexbox flex-tb flex-justify-center flex-align-center" v-if="!form.idCardBack">
                <i class="el-icon-plus avatar-uploader-icon"></i>
                <div class="upload-tips">法人身份证背面</div>
              </div>
            </div>
            <div class="upload-box pointer" @click="img_upload('hand_held_cert_pic_name_url')">
              <img v-if="form.hand_held_cert_pic_name_url" :src="form.hand_held_cert_pic_name_url" class="upload-img">
              <div class="del-img-box flexbox flex-justify-around flex-align-center" v-if="form.hand_held_cert_pic_name_url">
                <i class="el-icon-zoom-in" @click.stop="previewImgEvent('hand_held_cert_pic_name_url')"></i>
                <i class="el-icon-edit"></i>
              </div>
              <div class="upload flexbox flex-tb flex-justify-center flex-align-center" v-if="!form.hand_held_cert_pic_name_url">
                <i class="el-icon-plus avatar-uploader-icon"></i>
                <div class="upload-tips">手持证件照片</div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-item-box">
          <ApplyDetailFormItem v-for="(item) in formList.arr3" :key="item.label" :item="item" :form="form"
            :options="options" :disabled="disabled" />
          <ApplyDetailFormItem v-for="(item) in formList.arr4" :key="item.label" :item="item" :form="form"
            :options="options" :disabled="disabled" />
          <div class="form-item flexbox">
            <span>开户证明照片</span>
            <div class="upload-box pointer" @click="img_upload('account_open_license_pic_name_url')">
              <img v-if="form.account_open_license_pic_name_url" :src="form.account_open_license_pic_name_url" class="upload-img">
              <div class="del-img-box flexbox flex-justify-around flex-align-center" v-if="form.account_open_license_pic_name_url">
                <i class="el-icon-zoom-in" @click.stop="previewImgEvent('account_open_license_pic_name_url')"></i>
                <i class="el-icon-edit"></i>
              </div>
              <div class="upload flexbox flex-tb flex-justify-center flex-align-center" v-if="!form.account_open_license_pic_name_url">
                <i class="el-icon-plus avatar-uploader-icon"></i>
                <div class="upload-tips">开户证明照片</div>
              </div>
            </div>
          </div>
          <div class="form-item flexbox">
            <span>营业场所照片</span>
            <div class="upload-box pointer" @click="shop_img_upload(0)">
              <img v-if="form.shop_pic_url && form.shop_pic_url.length > 0 && form.shop_pic_url[0]" :src="form.shop_pic_url[0]" class="upload-img">
              <div class="del-img-box flexbox flex-justify-around flex-align-center" v-if="form.shop_pic_url && form.shop_pic_url.length > 0 && form.shop_pic_url[0]">
                <i class="el-icon-zoom-in" @click.stop="previewShopImg(0)"></i>
                <i class="el-icon-edit"></i>
              </div>
              <div class="upload flexbox flex-tb flex-justify-center flex-align-center" v-else>
                <i class="el-icon-plus avatar-uploader-icon"></i>
                <div class="upload-tips">营业场所照片</div>
              </div>
            </div>
            <div class="upload-box pointer" @click="shop_img_upload(1)">
              <img v-if="form.shop_pic_url && form.shop_pic_url.length > 0 && form.shop_pic_url[1]" :src="form.shop_pic_url[1]" class="upload-img">
              <div class="del-img-box flexbox flex-justify-around flex-align-center" v-if="form.shop_pic_url && form.shop_pic_url.length > 0 && form.shop_pic_url[1]">
                <i class="el-icon-zoom-in" @click.stop="previewShopImg(1)"></i>
                <i class="el-icon-edit"></i>
              </div>
              <div class="upload flexbox flex-tb flex-justify-center flex-align-center" v-else>
                <div class="upload-tips">营业场所照片</div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-item-box">
          <ApplyDetailFormItem v-for="(item) in formList.arr5" :key="item.label" :item="item" :form="form"
            :options="options" :disabled="disabled" />
          <!-- <div class="form-item flexbox">
            <span>现在经营地照片</span>
            <div class="upload-box pointer" @click="img_upload('shopPhoto')">
              <img v-if="form.shopPhoto" :src="form.shopPhoto" class="upload-img">
              <div class="upload flexbox flex-tb flex-justify-center flex-align-center" v-if="!form.shopPhoto">
                <i class="el-icon-plus avatar-uploader-icon"></i>
                <div class="upload-tips">店招门头照</div>
              </div>
            </div>
            <div class="upload-box pointer" @click="img_upload('goodsPhoto')">
              <img v-if="form.goodsPhoto" :src="form.goodsPhoto" class="upload-img">
              <div class="upload flexbox flex-tb flex-justify-center flex-align-center" v-if="!form.goodsPhoto">
                <i class="el-icon-plus avatar-uploader-icon"></i>
                <div class="upload-tips">场所内景照</div>
              </div>
            </div>
            <div class="upload-box pointer" @click="img_upload('idCardInhand')">
              <img v-if="form.idCardInhand" :src="form.idCardInhand" class="upload-img">
              <div class="upload flexbox flex-tb flex-justify-center flex-align-center" v-if="!form.idCardInhand">
                <i class="el-icon-plus avatar-uploader-icon"></i>
                <div class="upload-tips">收银台照</div>
              </div>
            </div>
          </div> -->
          <ApplyDetailFormItem v-for="(item) in formList.arr6" :key="item.label" :item="item" :form="form"
            :options="options" :disabled="disabled" />
          <!-- <div class="form-item flexbox" v-if="qrUrl">
            <span>该商户收款码</span>
            <img class="qrCodeImg" :src="qrUrl" alt="">
          </div> -->
        </div>
      </div>
      <div class="reason-box" v-if="handleType === '1'">
        <el-input
          type="textarea"
          :rows="4"
          placeholder="驳回原因"
          v-model="rejectReason">
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer" v-if="handleType === '1'">
        <el-button type="primary" @click="examine(2)">进件</el-button>
        <el-button @click="examine(3)">驳回</el-button>
      </span>
      <span slot="footer" class="dialog-footer" v-if="handleType === '2'">
        <el-button type="primary" @click="save">保存</el-button>
        <el-button @click="dialogVisible = false">返回</el-button>
      </span>
      <span slot="footer" class="dialog-footer" v-if="handleType === '3'">
        <el-button @click="dialogVisible = false">返回</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title=""
      :visible.sync="previewImgDialog"
      width="1000px">
      <img class="previewImg" :src="previewImg" alt="">
    </el-dialog>
  </div>
</template>
<script>
import { ChooseFile, FileUploader } from '@base/utils';
import {
  $sp_applyMerchantExamine, $sg_getListByTree, $sg_getCode,
  $sg_merchantInfo, $sp_merchantCreate,
} from '@/api/apply';
import { APPLY_DOMAIN } from '@/config/index';
import { getQrcodeSrc } from '@/global/globalMethods';
import ApplyDetailFormItem from './applyDetailFormItem.vue';
import formList from './formList';


export default {
  components: {
    ApplyDetailFormItem,
  },
  data() {
    return {
      mch_id: '',
      id: '',
      agent_id: '',
      handleType: '', // 操作类型
      dialogTitle: '', // 弹窗标题
      dialogVisible: false,
      disabled: false,
      form: {
        business_license_pic_name_url: '',
        idCardFront: '',
        idCardFrontUrl: '',
        idCardBack: '',
        idCardBackUrl: '',
        handHeldCertPicName: '',
        hand_held_cert_pic_name_url: '',
      },
      formList,
      formConfigList: [...formList.arr1, ...formList.arr2, ...formList.arr3, ...formList.arr4, ...formList.arr5, ...formList.arr6],
      options: {
        typeOptions: [
          {
            value: 2,
            label: '个体工商户',
          },
          {
            value: 3,
            label: '小微商户',
          },
        ],
        businessCertTypeOptions: [
          {
            value: '01',
            label: '身份证',
          },
          {
            value: '31',
            label: '营业执照',
          },
        ],
        bankTypeOptions: [
          {
            value: '00',
            label: '对公',
          },
          {
            value: '01',
            label: '对私',
          },
        ],
        settleTypeOptions: [
          {
            value: '0',
            label: 'T1',
          },
          {
            value: '2',
            label: 'D1',
          },
        ],
        addressOptions: [],
        bankAddressOptions: [],
        categoryOptions: [],
        bankOptions: [],
      },
      rejectReason: '', // 驳回原因
      qrUrl: '',
      previewImgDialog: false,
      previewImg: '',
    };
  },
  watch: {
    'form.company_type': {
      handler(val) {
        if (val === '2') {
          this.form.business_cert_type = '31';
        } else if (val === '3') {
          this.form.business_cert_type = '01';
        }
      },
    },
    form: {
      deep: true,
      immediate: true,
      // eslint-disable-next-line
      handler(form) {
        formList.rule.forEach((ruleItem) => {
          // eslint-disable-next-line no-eval
          const end = eval(ruleItem.condition);
          const formItem = this.formConfigList.find((temp) => temp.key === ruleItem.option.key);
          if (!formItem) return;
          if (end) {
            formItem.show = ruleItem.option.show;
            formItem.disabled = ruleItem.option.disabled;
          } else {
            formItem.show = true;
            formItem.disabled = false;
          }
        });
      },
    },
  },
  created() {
    this.formConfigList.forEach((item) => {
      this.$set(this.form, item.key, item.default || '');
    });
    this.getListByTree();
    this.getCode();
    this.getBank();
  },
  methods: {
    // 打开弹窗
    open(mch_id, id, agent_id, handleType) {
      this.rejectReason = '';
      this.qrUrl = '';
      this.mch_id = mch_id;
      this.id = id;
      this.agent_id = agent_id;
      this.merchantInfo();
      this.handleType = handleType;
      if (handleType === '1' || handleType === '3') {
        this.disabled = true;
      } else {
        this.disabled = false;
      }
      this.dialogVisible = true;
    },
    // 请求商户详情
    merchantInfo() {
      const params = {
        mch_id: this.mch_id,
      };
      $sg_merchantInfo(params).then((res) => {
        Object.keys(res).forEach((key) => {
          this.form[key] = res[key];
        });
        this.formConfigList.forEach((item) => {
          if (item.type === 'daterange') {
            if (this.form[item.endKey] === '30000101') {
              this.form[item.key] = '1';
              this.form[item.endKey] = '';
              this.form[item.startKey] = '';
            } else {
              this.form[item.key] = [this.form[item.startKey], this.form[item.endKey]];
            }
          }
          if (item.type === 'date') {
            if (this.form[item.key] === '30000101') {
              this.form[item.key] = '1';
            }
          }
        });
        this.form.shopAddress = [res.merchant_province, res.merchant_city, res.merchant_county];
        this.form.bankAddress = [res.bank_province_code, res.bank_city_code];
        this.$set(this.form, 'business_license_pic_name_url', res.business_license_pic_name_url);
        this.$set(this.form, 'hand_held_cert_pic_name_url', res.hand_held_cert_pic_name_url);
        this.$set(this.form, 'account_open_license_pic_name_url', res.account_open_license_pic_name_url);
        this.$set(this.form, 'shop_pic_url', res.shop_pic_url || '');
        this.form.shop_pic_url = this.form.shop_pic_url.split(';');
        this.$forceUpdate();
        const legal_entity_id_pic_name_url = res.legal_entity_id_pic_name_url.split(';');
        this.form.idCardFront = legal_entity_id_pic_name_url[0];
        this.form.idCardBack = legal_entity_id_pic_name_url[1];
        if (res.status === 4) {
          this.qrUrl = getQrcodeSrc(`${APPLY_DOMAIN}/userPay?shopid=${res.id}`);
        }
      });
    },
    // 请求省市区列表
    getListByTree() {
      $sg_getListByTree().then((res) => {
        this.options.addressOptions = res || [];
        this.handleTreeList(res);
      });
    },
    // 处理省市区列表
    handleTreeList(treeList) {
      if (!treeList) return;
      const tempArr = JSON.parse(JSON.stringify(treeList));
      tempArr.forEach((val) => {
        val.child.forEach((item, index) => {
          // eslint-disable-next-line no-param-reassign
          val.child[index] = {
            area_name: item.area_name,
            area_id: item.area_id,
          };
        });
      });
      this.options.bankAddressOptions = tempArr;
    },
    // 请求行业类别
    getCode() {
      const params = {
        type: 2,
      };
      $sg_getCode(params).then((res) => {
        const tempArr = [];
        res.forEach((val) => {
          tempArr.push({
            label: val.code_value,
            value: val.code,
          });
        });
        this.options.categoryOptions = tempArr;
      });
    },
    // 请求银行名称
    getBank() {
      const params = {
        type: 1,
      };
      $sg_getCode(params).then((res) => {
        const tempArr = [];
        res.list.forEach((val) => {
          tempArr.push({
            label: val.code_value,
            value: val.code,
          });
        });
        this.options.bankOptions = tempArr;
      });
    },

    // 上传图片
    img_upload(key) {
      if (this.disabled) return;
      const change = new ChooseFile();
      change.chooseImage((file) => {
        // if (file.size / 1024 / 1024 > 3.5) {
        //   this.$message.error('图片不能超过3.5M');
        //   return;
        // }
        this.getFile(file, key);
      });
    },
    // 文件上传
    getFile(file, key) {
      const fileUploder = new FileUploader(file, { addLibrary: false, compress: true });
      fileUploder.upload().then((url) => {
        this.$set(this.form, key, url);
        this.$set(this.form, `${key}Url`, url);
      }).catch((error) => {
        this.$message.error(error.message || '上传失败，请重新上传');
      });
    },

    // 上传营业场所照片
    shop_img_upload(type) {
      if (this.disabled) return;
      const change = new ChooseFile();
      change.chooseImage((file) => {
        const fileUploder = new FileUploader(file, { addLibrary: false, compress: true });
        fileUploder.upload().then((url) => {
          this.form.shop_pic_url[type] = url;
          this.$forceUpdate();
        }).catch((error) => {
          this.$message.error(error.message || '上传失败，请重新上传');
        });
      });
    },

    // 预览图片
    previewImgEvent(key) {
      this.previewImg = this.form[key];
      this.previewImgDialog = true;
    },
    // 预览营业场所照片
    previewShopImg(type) {
      this.previewImg = this.form.shop_pic_url[type];
      this.previewImgDialog = true;
    },
    // 审核
    examine(status) {
      let tips = '';
      if (status === 2) {
        tips = '确定要进件吗?';
      } else if (status === 3) {
        tips = '确定要驳回吗?';
      }
      this.$confirm(tips, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        status: 'warning',
      }).then(() => {
        if (status === 3 && !this.rejectReason) {
          this.$message.error('驳回原因不能为空');
          return;
        }
        const params = {
          id: this.id,
          status,
          reason: this.rejectReason,
        };
        $sp_applyMerchantExamine(params).then(() => {
          this.dialogVisible = false;
          this.$emit('applyMerchantList');
        });
      }).catch(() => {});
    },

    // 保存
    async save() {
      await this.$validator({
        ...this.form,
      }, this.formConfigList.filter((item) => item.validatRule).map((item) => `${item.key}/${item.validatRule}/${item.label}`));
      if (!this.form.licenceDate || this.form.licenceDate.length === 0) {
        this.$message.error('商户证件有效期不能为空');
        return;
      }
      if (+this.form.company_type === 3) {
        this.form.ctrl_entity_cert_type = '';
        this.form.benef_entity_cert_type = '';
        this.form.ctrl_entity_id = '';
        this.form.ctrl_name = '';
        this.form.ctrl_entity_id_valid = '';
        this.form.ctrl_rate = '';
        this.form.benef_name = '';
        this.form.benef_entity_id = '';
        this.form.benef_entity_id_valid = '';
        this.form.benef_rate = '';
        this.form.benef_entity_address = '';
      } else {
        this.form.ctrl_entity_cert_type = '01';
        this.form.benef_entity_cert_type = '01';
        if (!this.form.ctrl_name) {
          this.$message.error('控制人姓名不能为空');
          return;
        }
        if (!this.form.ctrl_entity_id) {
          this.$message.error('控制人身份证号不能为空');
          return;
        }
        if (!this.form.ctrl_entity_id_valid) {
          this.$message.error('控制人证件过期时间不能为空');
          return;
        }
        if (!this.form.ctrl_rate) {
          this.$message.error('控制人控股比例不能为空');
          return;
        }
        if (!this.form.benef_name) {
          this.$message.error('受益人姓名不能为空');
          return;
        }
        if (!this.form.benef_entity_id) {
          this.$message.error('受益人身份证号不能为空');
          return;
        }
        if (!this.form.benef_entity_id_valid) {
          this.$message.error('受益人证件过期时间不能为空');
          return;
        }
        if (!this.form.benef_rate) {
          this.$message.error('受益人控股比例不能为空');
          return;
        }
        if (!this.form.benef_entity_address) {
          this.$message.error('受益人地址不能为空');
          return;
        }
      }
      if (!this.form.business_license_pic_name_url) {
        this.$message.error('商户证件不能为空');
        return;
      }
      if (!this.form.shopAddress || this.form.shopAddress.length === 0) {
        this.$message.error('商户所在地不能为空');
        return;
      }
      if (!this.form.account_open_license_pic_name_url) {
        this.$message.error('开户证明照片不能为空');
        return;
      }
      if (!this.form.shop_pic_url || this.form.shop_pic_url.length < 2) {
        this.$message.error('营业场所照片不能为空');
        return;
      }
      this.form.shop_pic_url = this.form.shop_pic_url.join(';');
      if (+this.form.pay_rate < 0.25 || +this.form.pay_rate > 0.6) {
        this.$message.error('手续费比例不能高于0.6%，且不能低于0.25%');
        return;
      }
      if (!this.form.bankAddress[0] || !this.form.bankAddress[1]) {
        this.$message.error('开户行地址不能为空');
        return;
      }
      this.formConfigList.forEach((item) => {
        if (item.type === 'daterange') {
          if (+this.form[item.key] === 1) {
            if (item.startKey) {
              this.form[item.startKey] = '30000101';
            }
            if (item.endKey) {
              this.form[item.endKey] = '30000101';
            }
          } else {
            this.form[item.startKey] = this.form[item.key][0];
            this.form[item.endKey] = this.form[item.key][1];
          }
        }
        if (item.type === 'date') {
          if (+this.form[item.key] === 1) {
            if (item.key) {
              this.form[item.key] = '30000101';
            }
          }
        }
      });
      const form = JSON.parse(JSON.stringify(this.form));
      form.id = this.mch_id;
      form.agent_sid = this.agent_id;
      form.merchant_province = this.form.shopAddress[0];
      form.merchant_city = this.form.shopAddress[1];
      form.merchant_county = this.form.shopAddress[2];
      form.legal_entity_type = '01';
      form.contact_cert_type = '01';
      form.channel = '1';
      form.legal_entity_id_pic_name_url = `${this.form.idCardFront};${this.form.idCardBack}`;
      form.bank_province_code = this.form.bankAddress[0];
      form.bank_city_code = this.form.bankAddress[1];
      delete form.shopAddress;
      delete form.bankAddress;
      delete form.idCardFront;
      delete form.idCardBack;
      $sp_merchantCreate(form).then(() => {
        this.dialogVisible = false;
        this.$emit('applyMerchantList');
      });
    },
  },
};
</script>
<style lang="scss">
.applyDetail {
  .form-box {
    width: 100%;
    .form-item-box {
      flex: 1;
      .form-item {
        width: 100%;
        flex-shrink: 0;
        margin-top: 10px;
        & > span {
          width: 130px;
          text-align: right;
          margin-right: 10px;
        }
        .upload-box {
          width: 80px;
          height: 80px;
          margin-right: 5px;
          position: relative;
          .upload {
            width: 100%;
            height: 100%;
            border: 1px dashed #ccc;
            .upload-tips {
              width: 100%;
              text-align: center;
              font-size: 12px;
              color: #999;
            }
          }
          .upload-img {
            width: 100%;
            height: 100%;
          }
          .del-img-box {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.45);
            color: #fff;
            visibility: hidden;
            justify-content: space-around;
            & > i {
              cursor: pointer;
              user-select: none;
              font-size: 20px;
            }
          }
        }
        .upload-box:hover .del-img-box {
          visibility: visible;
        }
      }
      .qrCodeImg {
        width: 140px;
        height: 140px;
      }
    }
  }
  .reason-box {
    margin-top: 20px;
  }
  .el-dialog__footer {
    text-align: center;
  }
  .previewImg {
    width: 100%;
  }
}
</style>
