<template>
  <div>
    <div class="distribution-title">
      {{title}}
      <span v-if="scendTitle" class="isRequired">({{scendTitle}})</span>
      <span class="isRequired" v-if="required"> (必填) </span>
      <span class="notRequired" v-if="required===0"> (选填)</span>
    </div>
    <div class="pl30">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    scendTitle: {
      type: String,
      default: '',
    },
    required: {
      type: Number,
    },
  },
};
</script>

<style lang="scss" scoped>
.pl30{
  box-sizing: border-box;
  padding-left: 30px;
}
.distribution {
  .distribution-title {
    display: flex;
    align-items: center;
    margin: 20px 0;
    color: #444;
    font-weight: bold;
    .isRequired {
      color: #f63d2a;
    }
    .notRequired {
      color: #999999;
    }
  }
  .distribution-title::before {
    content: "";
    display: inline-block;
    height: 20px;
    width: 4px;
    background: #f63d2a;
    border-radius: 2px;
    margin-right: 10px;
  }
}
</style>
