<template>
  <div class="apply-page">
    <div class="search-box flexbox">
      <div class="search-item">
        <el-input v-model="form.mch_name" placeholder="商户名称" style="width: 240px;" clearable></el-input>
      </div>
      <div class="search-item">
        <el-input v-model="form.contact_phone" placeholder="商户账号" style="width: 240px;" clearable></el-input>
      </div>
      <div class="search-item">
        <el-select v-model="form.status" placeholder="当前状态" clearable>
          <el-option
            v-for="item in stateOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <el-button type="primary" @click="search">查询</el-button>
      <el-button type="info" @click="clearForm">清空</el-button>
    </div>
    <div class="table-box">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="id" label="序号" align="center"></el-table-column>
        <el-table-column prop="" label="商户名称" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.info.mch_name}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="" label="联系电话" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.info.contact_phone}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="" label="所在地" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.info.operate_address}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="申请时间" align="center"></el-table-column>
        <el-table-column prop="" label="经营类目" align="center">
          <template slot-scope="scope">
            <span type="text">{{ getIndustryCategoryName(scope.row.info.industry_category) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="companyType" label="商户类型" align="center">
          <template slot-scope="scope">
            <span type="text" v-if="scope.row.info.company_type == '1'">普通商户</span>
            <span type="text" v-if="scope.row.info.company_type == '2'">个体工商户</span>
            <span type="text" v-if="scope.row.info.company_type == '3'">小微商户</span>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="当前状态" align="center">
          <template slot-scope="scope">
            <span type="text" v-if="scope.row.status == 1">待平台审核</span>
            <span type="text" v-if="scope.row.status == 2">平台审核成功 等待渠道审核</span>
            <span type="text" v-if="scope.row.status == 3">平台审核失败</span>
            <span type="text" v-if="scope.row.status == 4">渠道审核成功</span>
            <span type="text" v-if="scope.row.status == 5">渠道审核失败</span>
          </template>
        </el-table-column>
        <el-table-column prop="" label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="operate(scope.row.mch_id, scope.row.id, scope.row.info.agent_id, '1')"
              v-if="scope.row.status == 1">审核</el-button>
            <el-button type="text" @click="operate(scope.row.mch_id, scope.row.id, scope.row.info.agent_id, '3')">详情</el-button>
            <el-button type="text" v-if="scope.row.status == 1 || scope.row.status == 3 || scope.row.status == 5"
              @click="operate(scope.row.mch_id, scope.row.id, scope.row.info.agent_id, '2')">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-box">
      <el-pagination
        :page-count="totalPage"
        :current-page.sync="currentPage"
        @current-change="applyMerchantList"
        layout="prev, pager, next, jumper">
      </el-pagination>
    </div>
    <ApplyDetail ref="applyDetail" @applyMerchantList="applyMerchantList" />
  </div>
</template>
<script>
import { $sg_applyMerchantList, $sg_getCode } from '@/api/apply';
import ApplyDetail from './components/applyDetail.vue';

export default {
  components: {
    ApplyDetail,
  },
  data() {
    return {
      form: {
        mch_name: '',
        status: '',
        contact_phone: '',
      },
      stateOptions: [
        {
          value: 1,
          label: '待平台审核',
        },
        {
          value: 2,
          label: ' 平台审核成功 等待渠道审核',
        },
        {
          value: 3,
          label: '平台审核失败',
        },
        {
          value: 4,
          label: '渠道审核成功',
        },
        {
          value: 5,
          label: '渠道审核失败',
        },
      ],
      tableData: [],
      totalPage: 1,
      currentPage: 1,
      industryCategory: [],
    };
  },
  created() {
    this.getCode();
    this.search();
  },
  methods: {
    // 搜索
    search() {
      this.currentPage = 1;
      this.applyMerchantList();
    },
    // 请求进件商户列表
    applyMerchantList() {
      const params = {
        page: this.currentPage,
        page_size: 10,
        ...this.form,
      };
      $sg_applyMerchantList(params).then((res) => {
        this.tableData = res.list;
        this.totalPage = Math.ceil(res.total / 10);
      });
    },
    getCode() {
      const paramsCode = {
        type: 2,
      };
      // 请求经营类目
      $sg_getCode(paramsCode).then((res) => {
        this.industryCategory = res;
      }); 
    },
    // 清空
    clearForm() {
      this.form = {
        mch_name: '',
        status: '',
        contact_phone: '',
      };
      this.search();
    },
    // 操作进件列表
    operate(mch_id, id, agent_id, handleType) {
      // handleType: 操作类型，1-审核，2-编辑，3-详情
      this.$refs.applyDetail.open(mch_id, id, agent_id, handleType);
    },
    // 获取经营类目
    getIndustryCategoryName(industryCategory) {
      const industryCategoryObj = this.industryCategory.find((val) => industryCategory === val.code);
      return industryCategoryObj ? industryCategoryObj.code_value : '-';
    },
  },
};
</script>
<style lang="scss" scoped>
.apply-page {
  width: 100%;
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
  .search-box {
    width: 100%;
    .search-item {
      margin-right: 20px;
    }
  }
  .table-box {
    width: 100%;
    margin-top: 40px;
    .el-table{
      min-height: 580px;
    }
  }
  .pagination-box {
    width: 100%;
    margin-top: 20px;
  }
}
</style>
